import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'

import { FormErrorSistel } from 'npm-styles-sistel'

export default function ErrorMessage({ msg }) {
  return (
    msg && (
      <FormErrorSistel>
        <Icon path={mdiAlertCircleOutline} title="Error" />
        {msg}
      </FormErrorSistel>
    )
  )
}
