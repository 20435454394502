import { NavElement } from './NavbarElements'

export default function UserIcon({ username, logout }) {
  return (
    <div className="user-icon">
      <img src="/images/icono-user.svg" />
      <h4>{username}</h4>
      <ul className="submenu">
        <div className="submenu-container">
          <NavElement to="/perfil" text="Perfil" item />
          <NavElement to="/session" text="Cerrar sesión" cb={logout} item />
        </div>
      </ul>
    </div>
  )
}
