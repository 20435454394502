import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'
import { NavLink } from 'react-router-dom'

import { ROLES } from 'utils/constants'

import FlexAlignCenter from 'components/common/FlexAlignCenter'

export default function NavBarElements({ role }) {
  switch (role) {
    case ROLES.USER:
      return (
        <ul>
          <NavElement to="/clientes" text="Clientes" item />
          <NavElement to="/devoluciones" text="Devoluciones" item />
          <NavElement to="/facturas" text="Facturas" item />
          <NavElement to="/remesas" text="Remesas" item />
        </ul>
      )
    default:
      return (
        <ul>
          <NavElement to="/clientes" text="Clientes" item />
          <NavElement to="/devoluciones" text="Devoluciones" item />
          <NavElement to="/facturas" text="Facturas" item />
          <NavElement to="/remesas" text="Remesas" item />
          <AdminDropdown />
        </ul>
      )
  }
}

export function NavElement({ to, text, item, cb }) {
  const isItem = item && 'item'

  return (
    <li className={isItem} onClick={cb}>
      <NavLink to={to} className="nav-link" activeClassName="active-nav-item">
        <FlexAlignCenter>
          <p className="text-link">{text}</p>
        </FlexAlignCenter>
      </NavLink>
    </li>
  )
}

function AdminDropdown() {
  return (
    <li className="item">
      <a className="nav-link nav-drop">
        Empresa <Icon path={mdiChevronDown} size={1} />
      </a>
      <ul className="submenu">
        <div className="submenu-container">
          <NavElement to="/usuarios" text="Usuarios" item />
          <NavElement to="/configuracion" text="Configuración" item />
          {/* <NavElement to="/informes" text="Informes" item /> */}
        </div>
      </ul>
    </li>
  )
}
