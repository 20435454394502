import LoginForm from 'components/forms/LoginForm'
import FlexAlignCenter from 'components/common/FlexAlignCenter'

import { FormLoginStyled } from 'styles/components/Login'

export default function Login() {
  return (
    <FlexAlignCenter className="min-vh-100 justify-content-center">
      <FormLoginStyled>
        <LoginForm />
      </FormLoginStyled>
    </FlexAlignCenter>
  )
}
