import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TOKEN } from 'utils/constants'
require('dotenv').config()

const { NODE_ENV, REACT_APP_API_URL } = process.env

const httpLink = createHttpLink({
  uri: NODE_ENV === 'production' ? REACT_APP_API_URL : 'http://localhost:4000'
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getInvoicesByStatus: {
            keyArgs: false,
            merge(
              existing = { invoices: [], totalCount: 0, offset: 0 },
              incoming
            ) {
              return incoming?.invoices
                ? {
                    offset: incoming.offset,
                    totalCount: incoming.totalCount,
                    invoices:
                      incoming.offset > 0
                        ? [...existing.invoices, ...incoming.invoices]
                        : incoming.invoices
                  }
                : existing
            }
          },
          getCancelledInvoices: {
            keyArgs: false,
            merge(
              existing = { invoices: [], totalCount: 0, offset: 0 },
              incoming
            ) {
              return incoming?.invoices
                ? {
                    offset: incoming.offset,
                    totalCount: incoming.totalCount,
                    invoices:
                      incoming.offset > 0
                        ? [...existing.invoices, ...incoming.invoices]
                        : incoming.invoices
                  }
                : existing
            }
          }
        }
      }
    }
  }),
  connectToDevTools: true
})
