import { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'

import TextInput from './common/TextInput'
import ErrorMessage from 'components/common/Error'

import { useAuth } from 'context/Auth'
import { LOGIN_USER } from 'gql/user'

import { ButtonByfi } from 'styles-byfi'

export default function LoginForm() {
  const { login } = useAuth()
  const [loginMutation, { data, error }] = useMutation(LOGIN_USER)

  useEffect(() => {
    if (data) login(data.login.token)
  }, [data])

  const handleSubmit = (values) => loginMutation({ variables: values })

  return (
    <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        const buttonText = isSubmitting ? 'Accediendo...' : 'Acceder'
        return (
          <Form>
            <TextInput label="Usuario" name="dni" />
            <TextInput label="Password" name="password" type="password" />
            {error && <ErrorMessage msg={error.message} />}
            <ButtonByfi primary block disabled={isSubmitting} className="my-2">
              {buttonText}
            </ButtonByfi>
          </Form>
        )
      }}
    </Formik>
  )
}

function initialValues() {
  return {
    dni: '',
    password: ''
  }
}
