import styled from 'styled-components'

import { ThemeByfi } from 'styles-byfi'

export const NavbarStyled = styled.div`
  background-color: ${ThemeByfi.colors.blanco};
  position: sticky;
  top: 0px;
  z-index: 10;
  box-shadow: 0px 6px 37px rgba(43, 17, 172, 0.1);

  .navbar-logo {
    max-width: 180px;
    position: absolute;
    top: 0;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-drop {
    margin-top: -2px;
  }

  nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;

      .item {
        position: relative;
        cursor: pointer;

        .text-link {
          font-size: 14px;
          font-weight: 500;
        }

        a {
          font-size: 14px;
          color: ${ThemeByfi.colors.gris_muy_oscuro};
          padding: 20px 0 16px 32px;
          display: block;
          font-weight: 500;
          transition: all 0.5s ease;
        }

        &:hover {
          a {
            color: ${ThemeByfi.colors.azul};
            font-style: italic;
          }
        }
      }
    }

    .submenu {
      display: none;
      transition: transform 0.5s ease;
    }
    .item:hover .submenu {
      display: block;
      position: absolute;
      inset: 0px auto auto 0px;
      margin: 0;
      transform: translate(30px, 36px);
      z-index: 99;

      .submenu-container {
        margin-top: 32px;
        min-width: 180px;
        padding: 24px;
        box-shadow: 0px 6px 30px rgba(123, 97, 255, 0.2);
        border-radius: 20px;
        background-color: ${ThemeByfi.colors.blanco};
      }

      a {
        font-family: ${ThemeByfi.fonts.montserrat};
        font-size: 16px;
        color: ${ThemeByfi.colors.lila};
        padding: 12px;
        font-style: normal;

        &:hover {
          color: ${ThemeByfi.colors.azul};
          font-style: italic;
        }
      }
    }
  }

  .active-nav-item {
    color: ${ThemeByfi.colors.azul} !important;
    font-weight: 700;
  }

  .user-icon {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 3px solid #eaeaf1;
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }

    h4 {
      margin-left: 10px;
      color: ${ThemeByfi.colors.lila};
      font-size: 16px;
    }

    .submenu {
      display: none;
      transition: transform 0.5s ease;
    }

    &:hover .submenu {
      display: block;
      position: absolute;
      margin: 0;
      transform: translate(0px, 76px);
      z-index: 99;

      .submenu-container {
        margin-top: 32px;
        min-width: 200px;
        padding: 12px;
        box-shadow: 0px 6px 30px rgba(123, 97, 255, 0.2);
        border-radius: 20px;
        background-color: ${ThemeByfi.colors.blanco};
      }

      a {
        font-family: ${ThemeByfi.fonts.montserrat};
        font-size: 16px;
        color: ${ThemeByfi.colors.gris_oscuro};
        padding: 12px;
        font-style: normal;

        &:hover {
          color: ${ThemeByfi.colors.gris_muy_oscuro};
          font-style: italic;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 12px;

    nav {
      ul {
        display: none;
      }
    }
  }
`

export const NavbarMenuMobileStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  padding: 6px 12px;
  background: ${ThemeByfi.colors.lila_oscuro};

  .item {
    width: 33%;
    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: ${ThemeByfi.fonts.montserrat};
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      svg {
        margin-bottom: 4px;
      }
    }

    .active-nav-item {
      color: ${ThemeByfi.colors.amarillo};
    }
  }

  @media (min-width: 1000px) {
    display: none;
  }
`
