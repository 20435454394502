import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

import { LoaderByfi } from 'styles-byfi'

export default function Routes() {
  const LazyDashboard = lazy(() => import('pages/Dashboard'))
  const LazyClients = lazy(() => import('pages/Clientes'))
  const LazyClientDetail = lazy(() => import('pages/ClienteDetalles'))

  const LazyInvoices = lazy(() => import('pages/Facturas'))
  const LazyInvoiceDetail = lazy(() => import('pages/FacturaDetalles'))
  const LazyInvoiceEdit = lazy(() => import('pages/FacturaEdit'))

  const LazyConsumptionDetails = lazy(() => import('pages/DesgloseConsumo'))
  const LazyBankOrder = lazy(() => import('pages/Remesas'))
  const LazyNewBankOrder = lazy(() => import('pages/RemesaNueva'))
  const LazyBankOrdDetail = lazy(() => import('pages/RemesaDetalles'))
  const LazyUsers = lazy(() => import('pages/Usuarios'))
  const LazyConfiguracion = lazy(() => import('pages/Configuracion'))
  const LazyPerfil = lazy(() => import('pages/Perfil'))
  const LazyDevoluciones = lazy(() => import('pages/Devoluciones'))

  // const LazyPdfTest = lazy(() => import('pages/PdfTest'))

  return (
    <Suspense fallback={<LoaderByfi />}>
      <Switch>
        <Route exact path="/" component={LazyDashboard} />
        {/* Clientes */}
        <Route exact path="/clientes" component={LazyClients} />
        <Route exact path="/clientes/:idCliente" component={LazyClientDetail} />
        {/* Devoluciones */}
        <Route exact path="/devoluciones" component={LazyDevoluciones} />
        {/* Facturas */}
        <Route exact path="/facturas" component={LazyInvoices} />
        <Route
          path="/facturas/desglose/:idFactura"
          component={LazyConsumptionDetails}
        />
        <Route
          exact
          path="/facturas/:idFactura/editar"
          component={LazyInvoiceEdit}
        />
        <Route path="/facturas/:idFactura" component={LazyInvoiceDetail} />
        {/* Remesas */}
        <Route exact path="/remesas" component={LazyBankOrder} />
        <Route exact path="/remesas/nueva" component={LazyNewBankOrder} />
        <Route exact path="/remesas/:idRemesa" component={LazyBankOrdDetail} />
        {/* Empresa */}
        <Route exact path="/usuarios" component={LazyUsers} />
        <Route exact path="/configuracion" component={LazyConfiguracion} />
        {/* Usuario */}
        <Route exatc path="/perfil" component={LazyPerfil} />
        {/* <Route exact path="/pdf-test" component={LazyPdfTest} /> */}
      </Switch>
    </Suspense>
  )
}
