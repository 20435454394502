import { useField } from 'formik'

import { InputByfi } from 'styles-byfi'

export default function TextInput({ label, type = 'text', ...props }) {
  const [field, meta] = useField(props)
  return (
    <InputByfi
      {...field}
      {...props}
      type={type}
      errors={meta.error}
      label={label}
    />
  )
}
