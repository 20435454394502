import styled from 'styled-components'

export const FormLoginStyled = styled.div`
  max-width: 500px;
  width: 50vw;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 6px 30px rgba(114, 142, 143, 0.2);
  padding: 32px;

  svg {
    margin-bottom: 12px;
  }

  @media all and (max-width: 1000px) {
    width: 85vw;
  }
`
