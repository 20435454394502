import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import jwtDecode from 'jwt-decode'

import { TOKEN } from 'utils/constants'

const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const history = useHistory()
  const [auth, setAuth] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem(TOKEN)
    if (token) {
      const isExpired = Date.now() > jwtDecode(token).exp * 1000
      isExpired
        ? logout()
        : setAuth({
            token,
            userId: jwtDecode(token)._id,
            role: jwtDecode(token).role
          })
    } else {
      setAuth(null)
    }
    setLoading(false)
  }, [])

  const login = (token) => {
    const { role, _id } = jwtDecode(token)
    localStorage.setItem(TOKEN, token)

    setAuth({
      token,
      userId: _id,
      role
    })
  }

  const logout = () => {
    history.push('/')
    setAuth(null)
    localStorage.removeItem(TOKEN)
  }

  const authData = useMemo(() => ({ auth, loading, login, logout }), [auth])

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthContext, useAuth }
