import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import UserIcon from './UserIcon'
import NavBarElements from './NavbarElements'

import { useAuth } from 'context/Auth'
import { GET_USER } from 'gql/user'

import { NavbarStyled } from 'styles/components/Navbar'

export default function UserNavbar() {
  const { auth, logout } = useAuth()
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: auth.userId },
    skip: Boolean(!auth.userId)
  })

  if (loading) return null

  return (
    <>
      <NavbarStyled>
        <div className="container navbar-content">
          <Link to="/">
            <img src="/images/logo-navbar.svg" className="navbar-logo" />
          </Link>
          <nav>
            <NavBarElements role={auth.role} />
            <UserIcon {...{ username: data?.getUser?.name, logout }} />
          </nav>
        </div>
      </NavbarStyled>
    </>
  )
}
