import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'

import App from './App'

import { client } from 'gql'
import { AuthProvider } from 'context/Auth'

import 'styles-byfi/src/bootstrap.css'
import { MainStylesByfi, ThemeByfi } from 'styles-byfi'

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ThemeProvider theme={ThemeByfi}>
        <AuthProvider>
          <MainStylesByfi>
            <App />
          </MainStylesByfi>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
