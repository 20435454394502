export const TOKEN = '@byfiFacturacionToken'

export const USER = 'user'
export const ADMIN = 'admin'
export const ROLES = [USER, ADMIN]

export const DRAFT = 'DRAFT'
export const PENDING = 'PENDING'
export const PAID = 'PAID'
export const CHARGED = 'CHARGED'
export const RETURNED = 'RETURNED'
export const INVOICE_STATUS = [DRAFT, PENDING, PAID, CHARGED, RETURNED]

export const BAJA = 'baja'
export const CLIENT = 'client'
export const CLIENT_STATUS = [BAJA, CLIENT]

export const EMITTED = 'EMITTED'
export const BANK_ORDER_STATUS = [PENDING, EMITTED, PAID]

export const SERVICES_TABLE_ELEMENTS = [
  'Cuota mensual',
  'Consumo',
  'Total sin descuentos o promociones',
  'Descuentos, promociones u otros',
  'Base Imponible',
  'Impuestos IVA (21,00 %)',
  'TOTAL FACTURA'
]

export const CONSUMPTION_TABLE_ELEMENTS = [
  'Llamadas nacionales',
  'Llamadas internacionales',
  'Red inteligente 90X',
  'Otras',
  'SMS',
  'Datos'
]

export const CONSUMPTION = 'CONSUMPTION'
export const DISCOUNT = 'DISCOUNT'
export const OTHERS = 'OTHERS'
export const LANDLINE_PHONE = 'LANDLINE_PHONE'
export const MOBILE_PHONE = 'MOBILE'
export const VARIABLE_FEES = [CONSUMPTION, DISCOUNT, OTHERS]
export const PHONE_SERVICES = [LANDLINE_PHONE, MOBILE_PHONE]

// Tables
export const INITIAL_STATE_VALUE = [DRAFT, PENDING, CHARGED, PAID, RETURNED]
export const TABLE_LIMIT = 24
export const TABLE_DEBOUNCE = 600

// TODO: Cambiar o eliminar, ya no se cargan devoluciones de SEPA
// Return codes
export const RETURN_SEPA_CODES = {
  AC01: 'Número de IBAN incorrecto',
  AC04: 'Número de cuenta cancelada',
  AC06: 'Cuenta bloqueada',
  AC13: 'Error en el tipo de cuenta del deudor',
  AG01: 'La cuenta no admite adeudos directos por razones de normativas',
  AG02: 'Código de operación, transacción o secuencia incorrecto',
  AM04: 'Saldo insuficiente',
  AM05: 'Cargo u operación duplicada',
  BE05: 'Error identificador del acreedor',
  CNOR: 'BIC banco acreedor no registrado',
  DNOR: 'BIC banco deudor no registrado',
  FF01: 'Formato de fichero XML erróneo',
  FF05: 'Código de transacción incorrecto (tipo de adeudo)',
  MD01: 'Mandato no válido o inexistente u operación no autorizada',
  MD02: 'Faltan datos del mandato o mandato incorrecto',
  MD06: 'No conforme con el cargo',
  MD07: 'Fallecimiento del deudor',
  MS02: 'Razón no especificada por el cliente',
  MS03: 'Razón no especificada por protección de datos',
  RC01: 'BIC inválido',
  RR01: 'Error en el IBAN del deudor',
  RR02: 'Falta el nombre del deudor',
  RR03: 'Falta nombre del acreedor',
  RR04: 'Error por motivos normativos',
  SL01: 'Servicios específicos del banco del deudor'
}
