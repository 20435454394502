import UserNavbar from 'components/navbar/UserNavbar'
import { useAuth } from 'context/Auth'
import { ToastContainer } from 'react-toastify'

import Login from 'pages/Login'
import Routes from 'routes'

import { LoaderByfi } from 'styles-byfi'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
  const { auth, loading } = useAuth()

  if (loading) return <LoaderByfi />
  if (auth === null) return <Login />

  return (
    <>
      <UserNavbar />
      <div className="main-container">
        <div className="container my-5">
          <Routes />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  )
}
