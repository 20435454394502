import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation ($data: createUserInput) {
    createUser(data: $data) {
      fullName
      role
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($data: updateUserInput) {
    updateUser(data: $data)
  }
`

export const DELETE_USER = gql`
  mutation ($id: ID!) {
    deactivateUser(_id: $id)
  }
`

export const LOGIN_USER = gql`
  mutation ($dni: String!, $password: String!) {
    login(dni: $dni, password: $password) {
      token
      user {
        fullName
        role
      }
    }
  }
`

export const GET_USERS = gql`
  query ($active: Boolean) {
    getAllUsers(active: $active) {
      fullName
      dni
      role
      _id
    }
  }
`

export const GET_USER = gql`
  query ($id: ID!) {
    getUser(_id: $id) {
      _id
      name
      lastname
      role
      dni
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation ($data: updatePasswordInput) {
    updateUserPassword(data: $data)
  }
`
